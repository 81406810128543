$headerColor: #333333;

h1 {
  font-size: 26px;
  display: block;
  color: $headerColor;
}

.map {
  height: 95vh;
  width: 100%;
}


.ol-popup {
        position: absolute;
        background-color: white;
        -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
        filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #cccccc;
        bottom: 12px;
        left: -50px;
        width: 400px;
        min-height: 200px;
      }
      
      .popup-img {
          margin-top: 3px;
      }
      
      .popup-date {
          font-size: 12px;
          color: #666;
      }
      
      
      .ol-popup:after, .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      .ol-popup:after {
        border-top-color: white;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
      }
      .ol-popup:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
      }
      .ol-popup-closer {
        text-decoration: none;
        position: absolute;
        top: 2px;
        right: 8px;
      }
      .ol-popup-closer:after {
        content: "✖";
      }
      
      .ol-popup .flags img {
        margin-right: 2px;
      }
      
      .distance {
          font-size: 16px;
      }
      
      .h2, h2 {
        font-size: 20px;
      }
      